<template>
  <div
    v-if="isVisible"
    class="modal fade show d-block"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ modalTitle }}</h5>
          <button
            type="button"
            class="close"
            @click="$emit('close')"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="handleSubmit">
          <div class="modal-body">
            <div class="form-group">
              <label for="tipo_valor">Tipo de Valor</label>
              <v-select
                v-model="valorForm.tipo_valor"
                :options="tiposValores"
                placeholder="Seleccionar Tipo"
                class="form-control"
              ></v-select>
            </div>
            <div class="form-group">
              <label for="precio">Precio</label>
              <input
                type="text"
                id="precio"
                v-model="valorForm.precio"
                class="form-control"
                placeholder="Precio"
              />
            </div>
            <div class="form-group">
              <label for="mes">Mes</label>
              <v-select
                v-model="valorForm.mes"
                :options="meses"
                placeholder="Seleccionar Mes"
                class="form-control"
              ></v-select>
            </div>
            <div class="form-group">
              <label for="año">Año</label>
              <input
                type="text"
                id="año"
                v-model="valorForm.año"
                class="form-control"
                placeholder="Año"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="$emit('close')">
              Cerrar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="!isFormValid"
            >
              {{ modalButton }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "ValoresFaltantesForm",
  components: {
    vSelect,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    modalTitle: {
      type: String,
      required: true,
    },
    modalButton: {
      type: String,
      required: true,
    },
    valorForm: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tiposValores: [
        { label: 'Proyección', value: '1' },
        { label: 'Valor Real', value: '2' },
      ],
      meses: [
        { value: 1, label: "Enero" },
        { value: 2, label: "Febrero" },
        { value: 3, label: "Marzo" },
        { value: 4, label: "Abril" },
        { value: 5, label: "Mayo" },
        { value: 6, label: "Junio" },
        { value: 7, label: "Julio" },
        { value: 8, label: "Agosto" },
        { value: 9, label: "Septiembre" },
        { value: 10, label: "Octubre" },
        { value: 11, label: "Noviembre" },
        { value: 12, label: "Diciembre" },
      ],
    };
  },
  computed: {
    isFormValid() {
      const { tipo_valor, precio, mes, año } = this.valorForm;
      return tipo_valor && precio && mes && año;
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('submit');
    },
  },
};
</script>

<style scoped>
/* Estilos aquí */
</style>
